import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/Index.vue"),
    meta: {
      title: "业务管理",
    },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/Test.vue"),
    meta: {
      title: "测试",
    },
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/Error.vue"),
    meta: {
      title: "页面不存在",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/salesman/Login.vue"),
    meta: {
      title: "绑定身份信息",
    },
  },
  {
    path: "/sale_binding",
    name: "sale_binding",
    component: () => import("@/views/salesman/Binding.vue"),
    meta: {
      title: "绑定身份信息",
    },
  },
  {
    path: "/recommendList",
    name: "recommendList",
    component: () => import("@/views/salesman/RecommendList.vue"),
    meta: {
      title: "推荐医生列表",
    },
  },
  {
    path: "/doc_binding",
    name: "doc_binding",
    component: () => import("@/views/doc/Binding.vue"),
    meta: {
      title: "关注服务号",
    },
  },
  {
    path: "/invite",
    name: "invite",
    component: () => import("@/views/doc/Invite.vue"),
    meta: {
      title: "邀请详情",
    },
  },
  {
    path: "/doc_audit",
    name: "doc_audit",
    component: () => import("@/views/doc/Audit.vue"),
    meta: {
      title: "审核结果",
    },
  },
  {
    path: "/docRegister",
    name: "docRegister",
    component: () => import("@/views/doc/DocRegister.vue"),
    meta: {
      title: "医生绑定",
    },
  },
  {
    path: "/docWithdraw",
    name: "docWithdraw",
    component: () => import("@/views/doc/DocWithdraw.vue"),
    meta: {
      title: "提现",
    },
  },
  {
    path: "/awardRule",
    name: "awardRule",
    component: () => import("@/views/doc/AwardRule.vue"),
    meta: {
      title: "奖励规则",
    },
  },
  {
    path: "/awardRule2",
    name: "awardRule2",
    component: () => import("@/views/doc/AwardRule2.vue"),
    meta: {
      title: "奖励规则",
    },
  },
  {
    path: "/financeList",
    name: "financeList",
    component: () => import("@/views/doc/FinanceList.vue"),
    meta: {
      title: "账户明细",
    },
  },
  {
    path: "/financeDesc",
    name: "financeDesc",
    component: () => import("@/views/doc/FinanceDesc.vue"),
    meta: {
      title: "详情",
    },
  },
  {
    path: "/statistics",
    name: "statistics",
    component: () => import("@/views/statistics/Statistics.vue"),
    meta: {
      title: "数据统计",
    },
  },
  {
    path: "/daypre",
    name: "daypre",
    component: () => import("@/views/statistics/DayPre.vue"),
    meta: {
      title: "每日处方列表",
    },
  },
  {
    path: "/examinationList",
    name: "examinationList",
    component: () => import("@/views/examination/ExaminationList.vue"),
    meta: {
      title: "订单列表",
      keepAlive: true,
    },
  },
  {
    path: "/examinationDetail",
    name: "examinationDetail",
    component: () => import("@/views/examination/ExaminationDetail.vue"),
    meta: {
      title: "订单详情",
    },
  },
  {
    path: "/doctorlist",
    name: "doctorlist",
    component: () => import("@/views/businessman/DoctorList.vue"),
    meta: {
      title: "医生认证情况",
      keepAlive: true,
    },
  },
  {
    path: "/predetail",
    name: "predetail",
    component: () => import("@/views/businessman/PreDetail.vue"),
    meta: {
      title: "医生开方情况",
    },
  },
  {
    path: "/awardList",
    name: "awardList",
    component: () => import("@/views/businessman/AwardList.vue"),
    meta: {
      title: "月度奖励",
    },
  },
  {
    path: "/costDescription",
    name: "costDescription",
    component: () => import("@/views/doc/CostDescription.vue"),
    meta: {
      title: "诊后管理费说明",
    },
  },
  {
    path: "/incomeList",
    name: "incomeList",
    component: () => import("@/views/app/IncomeList.vue"),
    meta: {
      title: "收入明细",
      keepAlive: true,
    },
  },
  {
    path: "/app_financeDesc",
    name: "app_financeDesc",
    component: () => import("@/views/app/FinanceDesc.vue"),
    meta: {
      title: "账户详情",
    },
  },
  {
    path: "/preSettlementList",
    name: "preSettlementList",
    component: () => import("@/views/app/PreSettlementList.vue"),
    meta: {
      title: "待结算明细",
    },
  },
  {
    path: "/preSettlementDesc",
    name: "preSettlementDesc",
    component: () => import("@/views/app/PreSettlementDesc.vue"),
    meta: {
      title: "明细详情",
    },
  },
  {
    path: "/incomeDesc",
    name: "incomeDesc",
    component: () => import("@/views/app/IncomeDesc.vue"),
    meta: {
      title: "明细详情",
    },
  },
  {
    path: "/amountDesc",
    name: "amountDesc",
    component: () => import("@/views/app/AmountDesc.vue"),
    meta: {
      title: "结算流程节点说明",
    },
  },
  //接诊开方统计
  {
    path: "/preForm",
    name: "preForm",
    component: () => import("@/views/app/PreForm.vue"),
    meta: {
      title: "接诊开方统计",
    },
  },
  //医生咨询详情
  {
    path: "/docInformationDesc",
    name: "docInformationDesc",
    component: () => import("@/views/app/DocInformationDesc.vue"),
    meta: {
      title: "资讯详情",
    },
  },
  //医生咨询详情
  {
    path: "/docVideoTutorialInfo",
    name: "doctorVideoTutorialInfo",
    component: () => import("@/views/app/DoctorVideoTutorialInfo.vue"),
    meta: {
      title: "使用指导详情",
    },
  },
  //成药详情
  {
    path: "/medicineDetail",
    name: "medicineDetail",
    component: () => import("@/views/app/MedicineDetail.vue"),
    meta: {
      title: "成药详情",
    },
  },
  //膏方详情
  {
    path: "/gaofangDetail",
    name: "gaofangDetail",
    component: () => import("@/views/app/GaofangDetail.vue"),
    meta: {
      title: "养生方详情",
    },
  },
  {
    path: "/diseaseScan",
    name: "diseaseScan",
    component: () => import("@/views/app/medicalRecord/DiseaseScan.vue"),
    meta: {
      title: "病历详情",
    },
  },
  {
    path: "/medicalRecord",
    name: "medicalRecord",
    component: () => import("@/views/app/medicalRecord/MedicalRecord.vue"),
    meta: {
      title: "我的问诊单",
    },
  },
  {
    path: "/medicalDesc",
    name: "medicalDesc",
    component: () => import("@/views/app/medicalRecord/MedicalDesc.vue"),
    meta: {
      title: "病历摘要",
    },
  },
  {
    path: "/answerTemplate",
    name: "answerTemplate",
    component: () => import("@/views/app/medicalRecord/AnswerTemplate.vue"),
    meta: {
      title: "我的问诊单",
    },
  },
  {
    path: "/webAuthorization",
    name: "webAuthorization",
    component: () => import("@/views/app/WebAuthorization.vue"),
    meta: {
      title: "系统授权",
    },
  },
  {
    path: "/webDocLogin",
    name: "webDocLogin",
    component: () => import("@/views/app/WebDocLogin.vue"),
    meta: {
      title: "医生电脑端登录",
    },
  },
  {
    path: "/bannerDesc",
    name: "bannerDesc",
    component: () => import("@/views/app/BannerDesc.vue"),
    meta: {
      title: "文章详情",
    },
  },
  {
    path: "/docInformationInfo",
    name: "docInformationInfo",
    component: () => import("@/views/app/DocInformationInfo.vue"),
    meta: {
      title: "文章详情",
    },
  },
  {
    path: "/action_xinrui",
    name: "action_xinrui",
    component: () => import("@/views/app/actions/Action_xinrui.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/action_xinrui2",
    name: "action_xinrui2",
    component: () => import("@/views/app/actions/Action_xinrui2.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/action_xinrui3",
    name: "action_xinrui3",
    component: () => import("@/views/app/actions/Action_xinrui3.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/action_xinrui4",
    name: "action_xinrui4",
    component: () => import("@/views/app/actions/Action_xinrui4.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/action_xinrui5",
    name: "action_xinrui5",
    component: () => import("@/views/app/actions/Action_xinrui5.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/action_xinrui6",
    name: "action_xinrui6",
    component: () => import("@/views/app/actions/Action_xinrui6.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/action_xinrui7",
    name: "action_xinrui7",
    component: () => import("@/views/app/actions/Action_xinrui7.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/action_xinrui8",
    name: "action_xinrui8",
    component: () => import("@/views/app/actions/Action_xinrui8.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/InvitingCourtesy",
    name: "InvitingCourtesy",
    component: () => import("@/views/app/actions/InvitingCourtesy.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/invitational_events",
    name: "invitational_events",
    component: () => import("@/views/app/actions/invitational_events.vue"),
    meta: {
      title: "医生邀请活动",
    },
  },
  {
    path: "/userList",
    name: "userList",
    component: () => import("@/views/app/actions/userList.vue"),
    meta: {
      title: "用户列表",
    },
  },
  {
    path: "/invitedDetails",
    name: "invitedDetails",
    component: () => import("@/views/app/actions/invitedDetails.vue"),
    meta: {
      title: "邀请用户订单奖励明细",
    },
  },
  {
    path: "/action_renxinzhi",
    name: "action_renxinzhi",
    component: () => import("@/views/app/actions/action_renxinzhi.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/action_renxinzhi1",
    name: "action_renxinzhi1",
    component: () => import("@/views/app/actions/action_renxinzhi1.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/action_renxinzhi2",
    name: "action_renxinzhi2",
    component: () => import("@/views/app/actions/action_renxinzhi2.vue"),
    meta: {
      title: "",
    },
  },

  {
    path: "/action_renxinzhi_record",
    name: "action_renxinzhi_record",
    component: () => import("@/views/app/actions/action_renxinzhi_record.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/action_status",
    name: "action_status",
    component: () => import("@/views/app/actions/action_status.vue"),
    meta: {
      title: "积分兑换",
    },
  },
  {
    path: "/year_end",
    name: "year_end",
    component: () => import("@/views/app/actions/year_end.vue"),
    meta: {
      title: "云上行医之路",
    },
  },
  {
    path: "/giftCard",
    name: "giftCard",
    component: () => import("@/views/app/actions/giftCard.vue"),
    meta: {
      title: "电子礼品卡",
    },
  },
  {
    path: "/downloadAppPage",
    name: "downloadAppPage",
    component: () => import("@/views/app/actions/downloadAppPage.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/salesmanReward",
    name: "salesmanReward",
    component: () => import("@/views/app/actions/SalesmanReward.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/monthReport",
    name: "monthReport",
    component: () => import("@/views/doc/MonthReport.vue"),
    meta: {
      title: "月报统计",
    },
  },
  {
    path: "/preList",
    name: "preList",
    component: () => import("@/views/doc/PreList.vue"),
    meta: {
      title: "开方数据",
    },
  },
  {
    path: "/consultLIst",
    name: "consultList",
    component: () => import("@/views/doc/ConsultList.vue"),
    meta: {
      title: "咨询数据",
    },
  },
  {
    path: "/performance",
    name: "performance",
    component: () => import("@/views/salesman/Performance.vue"),
    meta: {
      title: "月报数据",
    },
  },
  {
    path: "/targetlist",
    name: "targetlist",
    component: () => import("@/views/targetlist/TargetList.vue"),
    meta: {
      title: "全部指标",
    },
  },
  {
    path: "/invitepatient",
    name: "invitepatient",
    component: () => import("@/views/targetlist/InvitePatient.vue"),
    meta: {
      title: "患者数指标",
    },
  },
  {
    path: "/patientlist",
    name: "patientlist",
    component: () => import("@/views/targetlist/PatientList.vue"),
    meta: {
      title: "全部患者",
    },
  },
  {
    path: "/ordertarget",
    name: "ordertarget",
    component: () => import("@/views/targetlist/OrderTarget.vue"),
    meta: {
      title: "药品订单数指标",
    },
  },
  {
    path: "/orderlist",
    name: "orderlist",
    component: () => import("@/views/targetlist/OrderList.vue"),
    meta: {
      title: "药品订单报表",
    },
  },
  {
    path: "/ordermoney",
    name: "ordermoney",
    component: () => import("@/views/targetlist/OrderMoney.vue"),
    meta: {
      title: "药品订单数指标",
    },
  },
  {
    path: "/auditLicense",
    name: "AuditLicense",
    component: () => import("@/views/doc/AuditLicense.vue"),
    meta: {
      title: "多点执业备案",
    },
  },
  {
    path: "/dosageTip",
    name: "dosageTip",
    component: () => import("@/views/doc/DosageTip.vue"),
    meta: {
      title: "剂量说明",
    },
  },
  {
    path: "/giftDesc",
    name: "giftDesc",
    component: () => import("@/views/doc/GiftDesc.vue"),
    meta: {
      title: "礼品卡详情",
    },
  },
  {
    path: "/insureSub",
    name: "insureSub",
    component: () => import("@/views/insure/InsureSub.vue"),
    meta: {
      title: "提交投保信息",
    },
  },
  {
    path: "/insurePolicy",
    name: "insurePolicy",
    component: () => import("@/views/insure/InsurePolicy.vue"),
    meta: {
      title: "医责险电子保单",
    },
  },
  {
    path: "/allOrderDesc",
    name: "allOrderDesc",
    component: () => import("@/views/targetlist/AllOrderDesc.vue"),
    meta: {
      title: "药房总数据",
    },
  },
  {
    path: "/hospitalDesc",
    name: "hospitalDesc",
    component: () => import("@/views/targetlist/HospitalDesc.vue"),
    meta: {
      title: "当日所有药房数据",
    },
  },
  {
    path: "/singleHospitalDesc",
    name: "singleHospitalDesc",
    component: () => import("@/views/targetlist/SingleHospitalDesc.vue"),
    meta: {
      title: "单个药房数据",
    },
  },
  {
    path: "/evaluationModule/overview",
    name: "evaluationModuleOverview",
    component: () => import("@/views/app/evaluationModule/overview.vue"),
    meta: {
      title: "接诊月报",
    },
  },
  {
    path: "/evaluationModule/evaluationList",
    name: "evaluationList",
    component: () => import("@/views/app/evaluationModule/evaluationList.vue"),
    meta: {
      title: "我的评价",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // base: process.env.NODE_ENV === 'production' ? '/dev' : '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0,
      };
    }
  },
});

export default router;
